import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 232.000000 217.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,217.000000) scale(0.100000,-0.100000)">
<path d="M1414 2067 c-201 -42 -371 -215 -479 -486 -39 -100 -75 -218 -75
-250 0 -11 -14 -44 -31 -73 -51 -86 -72 -148 -71 -213 0 -59 -1 -61 -58 -120
-93 -97 -159 -203 -202 -325 -23 -66 -34 -219 -22 -316 l8 -69 7 100 c13 205
92 374 243 524 l64 63 41 -40 c163 -158 452 -102 634 123 114 142 116 361 3
404 -34 13 -41 13 -71 -3 -66 -36 -75 -138 -16 -183 31 -24 90 -11 118 26 11
14 13 14 14 1 4 -41 -5 -79 -28 -122 -73 -134 -239 -241 -425 -272 -72 -13
-191 33 -218 83 -9 18 -3 25 42 59 107 77 127 87 162 73 30 -11 32 -10 75 36
137 149 275 440 256 539 -6 35 -25 57 -25 29 0 -32 -60 -134 -97 -164 -24 -20
-70 -42 -123 -58 -47 -14 -118 -41 -159 -59 -41 -19 -76 -32 -78 -30 -8 8 27
115 66 207 103 237 238 383 408 440 93 31 225 25 303 -15 64 -32 131 -94 166
-154 83 -141 92 -356 24 -543 -74 -202 -266 -388 -552 -534 -53 -27 -95 -51
-94 -52 5 -6 83 25 167 65 358 171 565 442 586 768 19 299 -163 532 -430 549
-39 2 -100 -1 -133 -8z m-184 -721 c0 -12 -74 -129 -111 -175 -49 -63 -63 -70
-93 -42 -12 11 -27 21 -32 21 -6 0 -44 -23 -85 -51 -102 -71 -103 -71 -96 -20
13 97 106 173 302 247 55 20 115 31 115 20z"/>
<path d="M1443 1519 l-22 -40 32 -19 c18 -11 37 -20 44 -20 9 0 53 62 53 75 0
4 -75 45 -82 45 -2 0 -13 -18 -25 -41z"/>
<path d="M1223 1125 c-13 -19 -23 -37 -23 -39 0 -7 66 -46 78 -46 7 0 21 18
32 39 l20 39 -36 21 c-19 12 -38 21 -42 21 -4 0 -17 -16 -29 -35z"/>
<path d="M882 428 c3 -201 3 -203 25 -206 22 -3 22 -3 25 186 2 104 2 196 0
206 -2 9 -13 16 -28 16 l-24 0 2 -202z"/>
<path d="M1155 605 c-19 -18 -25 -35 -25 -70 0 -33 -4 -45 -15 -45 -8 0 -15
-7 -15 -15 0 -8 7 -15 15 -15 13 0 15 -20 15 -120 l0 -120 25 0 c14 0 25 3 25
8 1 4 2 57 3 117 l2 110 33 3 c43 4 43 30 0 34 -30 3 -33 6 -35 39 -4 48 17
68 53 49 19 -11 27 -11 37 -1 9 10 7 16 -12 32 -32 26 -77 24 -106 -6z"/>
<path d="M1574 586 c-9 -22 4 -36 32 -36 21 0 25 4 22 23 -4 26 -45 37 -54 13z"/>
<path d="M665 493 c-31 -8 -65 -34 -65 -49 0 -21 31 -30 41 -12 23 42 103 26
97 -19 -3 -22 -8 -25 -53 -28 -39 -2 -54 -9 -72 -30 -64 -74 20 -169 106 -121
21 12 28 12 47 0 23 -16 54 -9 54 11 0 7 -7 15 -15 19 -12 4 -15 25 -15 95 0
70 -4 93 -17 109 -19 22 -73 34 -108 25z m75 -177 c0 -46 -10 -56 -55 -56 -23
0 -45 25 -45 49 0 22 41 51 70 51 29 0 30 -2 30 -44z"/>
<path d="M1350 490 c-31 -8 -60 -32 -60 -52 0 -17 31 -20 48 -6 42 36 92 27
92 -16 0 -21 -6 -24 -55 -31 -42 -5 -59 -12 -75 -32 -59 -75 25 -166 110 -118
22 12 27 11 42 -2 10 -9 25 -12 37 -8 25 8 27 18 6 35 -11 9 -15 35 -15 105 0
75 -3 95 -17 107 -24 21 -70 28 -113 18z m80 -174 c0 -31 -5 -46 -16 -50 -29
-11 -53 -6 -68 15 -25 37 4 79 56 79 26 0 28 -2 28 -44z"/>
<path d="M1815 492 c-11 -2 -26 -9 -34 -16 -11 -8 -15 -8 -18 2 -3 6 -16 12
-29 12 l-24 0 0 -135 0 -135 25 0 25 0 0 105 c0 101 1 105 26 121 24 16 27 16
56 -1 24 -14 32 -15 38 -4 19 29 -21 60 -65 51z"/>
<path d="M1580 322 c0 -192 -7 -218 -54 -192 -33 17 -51 3 -30 -25 19 -26 86
-27 111 -2 16 15 18 42 21 202 l3 185 -25 0 -26 0 0 -168z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
